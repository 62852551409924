/*
 * This module imports and re-exports all Angular Material modules for convenience,
 * so only 1 module import is needed in your feature modules.
 * See https://material.angular.io/guide/getting-started#step-3-import-the-component-modules.
 *
 * To optimize your production builds, you should only import the components used in your app.
 */

import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {
  MAT_DATE_FORMATS,
  MAT_NATIVE_DATE_FORMATS,
  MAT_RIPPLE_GLOBAL_OPTIONS,
  MatCommonModule,
  MatLineModule,
  MatNativeDateModule,
  MatPseudoCheckboxModule,
  MatRippleModule,
  RippleGlobalOptions,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import {
  MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatLegacyFormFieldDefaultOptions as MatFormFieldDefaultOptions,
  MatLegacyFormFieldModule as MatFormFieldModule,
} from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import {
  MAT_LEGACY_MENU_DEFAULT_OPTIONS as MAT_MENU_DEFAULT_OPTIONS,
  MatLegacyMenuDefaultOptions as MatMenuDefaultOptions,
  MatLegacyMenuModule as MatMenuModule,
} from '@angular/material/legacy-menu';
import {
  MatLegacyPaginatorIntl as MatPaginatorIntl,
  MatLegacyPaginatorModule as MatPaginatorModule,
} from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TgFormsModule } from '@app/components/tg-forms/tg-forms.module';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { RxFor } from '@rx-angular/template/for';
import { RxIf } from '@rx-angular/template/if';
import { RxLet } from '@rx-angular/template/let';
import { RxPush } from '@rx-angular/template/push';
import { RxUnpatch } from '@rx-angular/template/unpatch';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import {
  BREAKPOINT,
  DEFAULT_BREAKPOINTS,
  DEFAULT_CONFIG as FLEX_DEFAULT_CONFIG,
  LAYOUT_CONFIG,
} from 'ngx-flexible-layout/core';
import { CustomDirectivesModule } from './_directives/custom-directives.module';
import { CustomPipesModule } from './_pipes/custom-pipes.module';
import { AngularExpansionModule } from './angular-expansion/angular-expansion.module';
import { MatPaginatorI18NService } from './services/mat-paginator/mat-paginator-i18-n.service';
import { LoaderModule } from './shared/loader/loader.module';
import { MatRightSideSheetModule } from './shared/mat-right-side-sheet/mat-right-side-sheet.module';
import { MatSideSheetModule } from './shared/mat-side-sheet/mat-side-sheet.module';
import { MatTooltipTippyModule } from './shared/mat-tooltip-tippy/mat-tooltip-tippy.module';

@NgModule({
  imports: [RxFor, RxLet, RxPush, RxIf, RxUnpatch],
  exports: [
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatCommonModule,
    // dt
    MatDatepickerModule,
    MatNativeDateModule,
    // MatMomentDateModule,
    // dt end
    MatDialogModule,
    MatSideSheetModule,
    MatRightSideSheetModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatLineModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatPseudoCheckboxModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipTippyModule,
    LoaderModule,
    SatPopoverModule,
    AngularExpansionModule,
    CustomDirectivesModule,
    CustomPipesModule,
    TgFormsModule,
    FlexLayoutModule,
    RxFor,
    RxLet,
    RxPush,
    RxIf,
    RxUnpatch,
  ],
})
export class MaterialModule {
  static forRoot(): ModuleWithProviders<MaterialModule> {
    return {
      ngModule: MaterialModule,
      providers: [
        { provide: LAYOUT_CONFIG, useValue: { ...FLEX_DEFAULT_CONFIG } },
        { provide: BREAKPOINT, useValue: DEFAULT_BREAKPOINTS, multi: true },
        { provide: MAT_DATE_FORMATS, useValue: MAT_NATIVE_DATE_FORMATS }, // MAT_MOMENT_FORMATS or MAT_NATIVE_DATE_FORMATS (date = more rawr performance)
        {
          provide: MAT_MENU_DEFAULT_OPTIONS,
          useValue: <MatMenuDefaultOptions>{
            overlapTrigger: true,
          },
        },
        {
          provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
          useValue: <MatFormFieldDefaultOptions>{
            appearance: 'standard',
          },
        },
        { provide: MatPaginatorIntl, useClass: MatPaginatorI18NService },
        {
          provide: MAT_RIPPLE_GLOBAL_OPTIONS,
          useValue: <RippleGlobalOptions>{
            animation: {
              enterDuration: 280,
              exitDuration: 120,
            },
            terminateOnPointerUp: true,
          },
        },
      ],
    };
  }
}
