import { BreakpointObserver } from '@angular/cdk/layout';
import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { MatDivider } from '@angular/material/divider';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@Directive({
  selector: 'mat-divider[detail-divider]',
})
export class MatDividerMqDirective implements OnInit {
  @Input('detail-divider')
  private _verticalMedia = new BehaviorSubject<boolean>(null);
  readonly verticalMedia$ = this._verticalMedia.asObservable().pipe(distinctUntilChanged());
  get verticalMedia() {
    return this._verticalMedia.getValue();
  }
  set verticalMedia(val: boolean) {
    this._verticalMedia.next(val);
  }

  constructor(private matDivider: MatDivider, private bp: BreakpointObserver, private el: ElementRef<HTMLElement>) {}
  ngOnInit() {
    combineLatest([this.verticalMedia$, this.bp.observe('(max-width: 1279px)')])
      .pipe(filter(([v]) => v !== this.matDivider.vertical))
      .subscribe(([v, m]) => {
        if (v !== false && m.matches) {
          this.matDivider.vertical = false;
        } else {
          this.matDivider.vertical = true;
        }
      });
  }
}
