"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = addLeadingZeros;
function addLeadingZeros(number, targetLength) {
  var sign = number < 0 ? '-' : '';
  var output = Math.abs(number).toString();
  while (output.length < targetLength) {
    output = '0' + output;
  }
  return sign + output;
}
module.exports = exports.default;