import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clsx',
  standalone: true,
})
export class ClsxPipe implements PipeTransform {
  transform(arg: string | string[]): unknown {
    const value = !arg ? null : Array.isArray(arg) ? arg : [arg];
    if (!value) return null;
    return value.reduce((acc, c) => {
      const classes = c?.split?.(' ');
      if (classes?.length) {
        acc.push(...classes.filter((x) => x && !acc.find((d) => d === x)));
      }
      return acc;
    }, [] as string[]);
  }
}
