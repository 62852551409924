import { AfterViewInit } from '@angular/core';
import { ViewContainerRef } from '@angular/core';
import { Directive, HostListener, Injectable, ContentChild, ViewChild, Input, OnInit, ElementRef } from '@angular/core';
import {
  RouterLink,
  UrlTree,
  Router,
  ActivatedRoute,
  Route,
  NavigationExtras,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { environment } from '@env/environment';
import { isTeamsWindow } from '@env/msal';
import { Logger } from 'timeghost-api';

declare const window: Window;
const log = new Logger('TeamsExternaLinkDirective');

export const TeamUtils = {
  parseUrl(
    router: Router,
    relativeTo?: ActivatedRoute | ActivatedRouteSnapshot,
    routerLink?: string[],
    options: NavigationExtras = {}
  ) {
    const rl = router.serializeUrl(
      router.createUrlTree(routerLink || [], !!relativeTo ? <NavigationExtras>{ relativeTo, ...options } : options)
    );
    const url = environment.clientUrl;
    const ul = new URL(rl.toString(), url);
    return new URL(`${url}/${ul.pathname.indexOf('/') === 0 ? ul.pathname.slice(1) : ul.pathname}`, url).toString();
  },
  clickLink(url: string) {
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.click();
    a.remove();
  },
};

@Directive({
  selector: '[routerLink][teamsExternalLink],[routerLink][teams-external]',
})
export class TeamsExternaLinkDirective implements OnInit, AfterViewInit {
  @Input('routerLink')
  private _rl: any | any[];
  constructor(private router: Router, private route: ActivatedRoute, private ref: ElementRef<HTMLElement>) {}
  ngAfterViewInit(): void {
    if (window.teamsMobile) this.ref.nativeElement?.remove();
  }
  ngOnInit(): void {}
  @HostListener('click', ['$event'])
  onRouterClick(ev: Event) {
    if (!isTeamsWindow()) {
      return true;
    }
    ev.stopPropagation();
    ev.preventDefault();
    if (window.teamsMobile) {
      this.router.navigate(this.route.snapshot.url);
      return false;
    }
    TeamUtils.clickLink(
      TeamUtils.parseUrl(this.router, this.route, typeof this._rl === 'string' ? [this._rl] : this._rl)
    );
    this.router.navigate(this.route.snapshot.url);
    return false;
  }
}
